<template>
  <div>
    <loading v-if="Loading"/>

    <!-- Tabel -->
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="tableData" :fields="fields" :per-page="perPage" :current-page="currentPage">
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="tableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    quillEditor
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      fields: [
        {key:"title",label:"Title"},
        {key:"description",label:"Descriiptiom"},
        {key:"log",label:"Log"},
        {key:"created_date",label:"Created Date"},
        {key:"created_by",label:"Created By"}
      ],
      statusOption: [
        { text: 'Tampil', value: 1 },
        { text: 'Tidak Tampil', value: 0 }
      ],
      modalShow: false,
      formPasal: _interfaces.master_pasal,
      tableData: [],
      dataWarga: [],
      content: '',
      isEdit: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'list': 'bullet' }, { 'list': 'ordered' }], // Untuk daftar tak berurut dan berurut
            ['bold', 'italic'] // Untuk bold dan italic
          ]
        }
      }
    };
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      axios.post("idp/master/syslog/list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>